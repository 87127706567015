// const environment = "DEV";
const environment = "PROD";
// const environment = "STAGE";




let AWS_BUCKET_NAME, DEFAULT_APP_URL, SUCCESS_URL, CANCEL_URL;

switch (environment) {
  case "PROD":
    AWS_BUCKET_NAME = process.env.REACT_APP_AWS_BUCKET_NAME_PROD;
    DEFAULT_APP_URL = process.env.REACT_APP_BASE_URL_PROD;
    SUCCESS_URL = "https://partner.alleshealth.com/payment-successful";
    CANCEL_URL = "https://partner.alleshealth.com/payment-failed";
    break;
  case "STAGE":
    AWS_BUCKET_NAME = process.env.REACT_APP_AWS_BUCKET_NAME_STAGE;
    DEFAULT_APP_URL = process.env.REACT_APP_BASE_URL_STAGE;
    SUCCESS_URL = "https://stagepartner.alleshealth.com/payment-successful";
    CANCEL_URL = "https://stagepartner.alleshealth.com/payment-failed";
    break;
  default:
    AWS_BUCKET_NAME = process.env.REACT_APP_AWS_BUCKET_NAME_DEV;
    DEFAULT_APP_URL = process.env.REACT_APP_BASE_URL_DEV;
    SUCCESS_URL = "https://devpartner.alleshealth.com/payment-successful";
    CANCEL_URL = "https://devpartner.alleshealth.com/payment-failed";
}

const Constant = {
  AWS_KEY: process.env.REACT_APP_AWS_KEY,
  AWS_SECRET_KEY: process.env.REACT_APP_AWS_SECRET_KEY,
  AWS_REGION: process.env.REACT_APP_AWS_REGION,
  AWS_BUCKET_NAME,
  DEFAULT_APP_URL,
  SUCCESS_URL,
  CANCEL_URL,
};

export default Constant;
